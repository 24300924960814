import React, { useState, useRef } from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Hero from './jp-portrait-big-crop.png'
import Header from './Header.js'
import { motion } from "framer-motion"
import InitialTransition from "./InitialTransition.js"

function About() {
  const [isLoaded, setIsLoaded] = useState(false);
  const heroClasses = isLoaded ? "landing-hero" : "landing-hero"
  const aboutContent = useMediaQuery('(max-width:600px)') ? (
    <>
      <Grid item xs={4}>
        <div style={{ width: '100%', minHeight: '100vw' }}>
          <img className={heroClasses} src={Hero} />
        </div>
      </Grid>
      <Grid container item justifyContent="flex-end" alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h3" gutterBottom component="div" sx={{ 'textAlign': 'right', 'marginBottom': '0px' }}>
            Hoy, ako si Justin!</Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" alignItems="center">
        <Typography variant="body1" gutterBottom component="div" sx={{ 'textAlign': 'right' }}>
          (That's Tagalog for "Hey, I'm Justin! ")</Typography>
      </Grid>
      <Grid container item>
        <Stack spacing={2}>
          <Typography variant="body2" gutterBottom component="div">Hey, I'm Justin (he/him)!</Typography>
          <Typography variant="body2" gutterBottom component="div">I’m software engineer and a creative writer.</Typography>
          <Typography variant="body2" gutterBottom component="div">I recently graduated with a major in Computer Science. I have built and taken the lead on developing software ever since I fell in love with coding in high school. I also have experience in web design, web administration, and IT. </Typography>
          <Typography variant="body2" gutterBottom component="div">Outside of tech, I’m a writer that weaves between media, culture, politics, history, and identity.</Typography>
          <Typography variant="body2" gutterBottom component="div">I like to think of my work with technology as supplementary rather than separate from the my creative side. My end goal is to produce meaningful, valuable work for others, however unconventional, non-linear, or offbeat the means may be.</Typography>
        </Stack>
      </Grid>
    </>
  ) : (
    <>
      <Grid item md={1}></Grid>
      <Grid item md={5} container direction="column" justifyContent="center">
        <Typography variant="h1" component="div">
          Hoy, ako si Justin!
        </Typography>
        <Typography variant="h4" gutterBottom component="div" >
          (That's Tagalog for "Hey, I'm Justin!")
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body2" gutterBottom component="div">Hey, I'm Justin (he/him)!</Typography>
          <Typography variant="body2" gutterBottom component="div">I’m software engineer and a creative writer.</Typography>
          <Typography variant="body2" gutterBottom component="div">I recently graduated with a major in Computer Science. I have built and taken the lead on developing software ever since I fell in love with coding in high school. I also have experience in web design, web administration, and IT. </Typography>
          <Typography variant="body2" gutterBottom component="div">Outside of tech, I’m a writer that weaves between media, culture, politics, history, and identity.</Typography>
          <Typography variant="body2" gutterBottom component="div">I think of my creative work as supplementary rather than separate from my technical work. My end goal is to produce meaningful, valuable work for others, however unconventional, non-linear, offbeat the means may be.</Typography>
        </Stack>
      </Grid>
      <Grid item md={5}>
        <img style={{ width: "100%" }} src={Hero} />
      </Grid>
      <Grid item md={1}></Grid>
    </>
  )
  return (
    <motion.div
      id="about"
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <InitialTransition bgColor="rgb(5, 60, 94)" />
        <Header color="yellow" />
        {aboutContent}
      </Grid>
    </motion.div>
  );
}

export default About;
