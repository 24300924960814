import { React } from 'react';
import './App.css';
import Landing from './Landing.js'
import About from './About.js'
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {AnimatePresence}  from "framer-motion"

const landingTheme = createTheme({
    typography: {
        h1: {
            fontFamily: 'Arimo',
            fontWeight: 'bold',
            color: '#053C5E',
            fontSize: '96px',
            '@media (max-width:900px)': {
                fontSize: '48px',
            },
        },
        h2: {
            fontFamily: 'Arimo',
            fontStyle: 'normal',
            fontSize: '60px',
            color: '#0D5B8B',
            '@media (max-width:900px)': {
                fontSize: '34px',
            },
        },
        h3: {
            fontFamily: 'Arimo',
            fontWeight: 'bold',
            fontSize: '48px',
            color: '#053C5E'
        },
        h4: {
            fontFamily: 'Arimo',
            fontStyle: 'normal',
            fontSize: '34px',
            color: '#0D5B8B'
        },
        body1: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '16px',
            color: '#053C5E',
            lineHeight: 'normal',
        }
    },

});

const aboutTheme = createTheme({
    typography: {
        h1: {
            fontFamily: 'Arimo',
            fontWeight: 'bold',
            color: '#FFC26C',
            fontSize: '96px'
        },
        h2: {
            fontFamily: 'Arimo',
            fontStyle: 'normal',
            fontSize: '60px',
            color: '#FFC26C'
        },
        h3: {
            fontFamily: 'Arimo',
            fontWeight: 'bold',
            fontSize: '48px',
            color: '#FFC26C'
        },
        h4: {
            fontFamily: 'Arimo',
            fontStyle: 'normal',
            fontSize: '34px',
            color: '#FFC26C'
        },
        body1: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '16px',
            color: '#FEE7C7',
            lineHeight: 'normal',
        },
        body2: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '14px',
            color: '#F9D39D',
            lineHeight: 'normal',
        }
    },

});




function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={
                    <ThemeProvider theme={landingTheme}><Landing /></ThemeProvider>
                }
                />
                <Route path="/about" element={
                    <ThemeProvider theme={aboutTheme}><About /></ThemeProvider>
                }
                />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;