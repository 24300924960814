import React, { useState } from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Hero from './portfolio-landing.png';
import Header from './Header.js';
import {motion} from "framer-motion"
import InitialTransition from "./InitialTransition.js"

function Landing() {
  const [isLoaded, setIsLoaded] = useState(false);
  const thumbClasses = isLoaded ? "landing-thumb thumb loadOut" : "landing-thumb thumb"
  const heroClasses = isLoaded ? "landing-hero isLoaded" : "landing-hero"
  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  const landingContent = useMediaQuery('(max-width:600px)') ? (
    <>
      <Grid container item>
        <Grid container item xs={3}>
          <Typography variant="h1" gutterBottom component="div">
            Yo, I'm Justin Pacquing!
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} style={{ zIndex: 2 }}>
        <Typography variant="h2" gutterBottom component="div" >
          Programmer,
          Writer,
          Creative
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <img className={heroClasses} src={Hero} onLoad={handleOnLoad} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" gutterBottom component="div">
          I’m passionate about problem solving,
          curious in the world and its challenges,
          and driven to imbue empathy into my work however it may manifest.</Typography>
      </Grid>
    </>
  ) : (
    <>
        <Grid item md={1}></Grid>
        <Grid item md={6} container direction="column" justifyContent="center">
          <Typography variant="h1" gutterBottom component="div">
            Yo, I'm Justin Pacquing!
          </Typography>
          <Typography variant="h2" gutterBottom component="div" >
            Programmer,
            Writer,
            Creative
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            I’m passionate about problem solving,
            curious in the world and its challenges,
            and driven to imbue empathy into my work however it may manifest.</Typography>
        </Grid>
        <Grid item md={3}>
          <img className={heroClasses} src={Hero} onLoad={handleOnLoad} />
        </Grid>
        <Grid item md={3}></Grid>
    </>
  )
  const content = {
    animate: {
      transition: { staggerChildren: 0.1, delayChildren: 2.8 },
    },
  };

  const title = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const products = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };
  
  return (
    <motion.div 
      id="landing"
      intial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity:0}}
    >
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Header />
        <InitialTransition />
        {landingContent}
      </Grid>
    </motion.div>
  );
}

export default Landing;
