import { React } from 'react';
import './App.css';
import AnimatedRoutes from './AnimatedRoutes.js'
import { BrowserRouter as Router } from "react-router-dom";

function App() {
    return (
        <div >
            <AnimatedRoutes />
        </div>
    );
}

export default App;