import { React } from 'react';
import { motion } from "framer-motion"

function InitialTransition(props) {
    const bgColor = props.bgColor ? props.bgColor : "rgb(255, 228, 191)"
    const blackBox = {
        initial: {
            height: "100vh",
            bottom: 0,
        },
        animate: {
            height: 0,
            transition: {
                when: "afterChildren",
                duration: 1.5,
                ease: [0.87, 0, 0.13, 1],
            },
        },
    };

    return (
        <div
            style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <motion.div
                style={{
                    position: "relative",
                    width: "100vw",
                    backgroundColor: bgColor,
                    zIndex: "50"
                }}
                initial="initial"
                animate="animate"
                variants={blackBox}  >
            </motion.div>
        </div>
    );
}

export default InitialTransition;